/*--------------------------
    Color Darker Version  
----------------------------*/

.active-dark {
    background: #240000;
    min-height: calc(100vh);
    background-image: url(/assets/images/pattern.png) !important;
    background-size: 20%;
    background-position: 50% 50%;
    [data-overlay="6"]:before,
    [data-black-overlay="6"]:before,
    [data-white-overlay="6"]:before {
        display: none;
    }
    [data-overlay]:before,
    [data-black-overlay]:before,
    [data-white-overlay]:before {
        display: none;
    }
    .service-white .service__style--1 .content h4.title{
        color: #ffffff;
    }
    .about-area {
        height: 540px;
        padding: 72px 0;
        .art-bottom {
            content: '';
            display: block;
            height: 60px;
            width: 100%;
            background: url(/assets/images/borders/border.svg) no-repeat;
            background-size: 10000px;
            background-position: center;
            position: absolute;
            top: 768px;
            left: 50%;
            transform: translateX(-50%) translateY(-100%) scaleY(-1);
            z-index: 1;
        }
    }

    .service-area {
        .art-bottom {
            content: '';
            display: block;
            height: 60px;
            width: 100%;
            background: url(/assets/images/borders/border.svg) no-repeat;
            background-size: 10000px;
            background-position: center;
            position: absolute;
            top: 768px;
            left: 50%;
            transform: translateX(-50%) translateY(-100%) scaleY(-1);
            z-index: 1;
        }
    }

    .bg_image--1 {
        background-image: url('/assets/images/bg/deco.svg'), url('/assets/images/bg/bg.svg') !important;
        background-repeat: repeat;
        background-size: 105%, 150%;
        background-position: 50% 53%, 50% 50%, 50% 32%;
        height: 540px;
        padding: 0;
    }

    .bg_image--2 {
        height: 130px;
        background-image: url('/assets/images/bg/bg1.svg') !important;
        background-size: 20%;
        background-repeat: repeat;
    }

    .bg_image--3 {
        min-height: 446px;
        background: #24000000 !important;
    }

    .rn-testimonial-content .inner p::before,
    .rn-testimonial-content .inner p::after ,
    .footer-right  {
        background-image: inherit !important;
    }
    .bg_color--1 ,
    .brand-separation {
        background: #1f0000;
    }
    .bg_color--5,
    .footer-right {
        background-image: url(/assets/images/bg/deco.svg),url(/assets/images/bg/bg.svg) !important;
        background-repeat: repeat;
        background-size: 270%,600%;
        background-position: 56% 50%,19% 40%,50% 32%;
    }
    ul.brand-style-2 img {
        filter: invert(1);
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        color: #ffffff;
    }
    h2 {
        font-size:29px;
    }
    a.btn-transparent {
        color: #ffffff; 
    }
    .section-title p ,
    .about-us-list p ,
    .about-inner .section-title p.description,
    .service.service__style--2 .content p ,
    .counterup_style--1 p.description ,
    .rn-testimonial-content .author-info h6 ,
    .slide.slider-style-3 .inner>span ,
    .single-tab-content ul li a ,
    .single-tab-content ul li {
       color: rgb(211, 203, 187)
    }

    ul.tab-style--1 li::before {
        background: rgba(198, 201, 216, 0.75);
    }

    ul.tab-style--1 li.react-tabs__tab--selected {
        color: $theme-color;
    }
    ul.tab-style--1 li.react-tabs__tab--selected::before {
        background: currentColor;
    }

    .service.service__style--2:hover .content h3.title,
    .service.service__style--2:hover .content p,
    .rn-testimonial-content .author-info h6 span ,
    .rn-testimonial-content .inner p ,
    .section-title p a ,
    .slide.slider-style-3 .inner h1.title ,
    .color-black .mainmenunav ul.mainmenu>li>a ,
    ul.social-share.social-style--2.color-black li a ,
    .header-area.color-black a.rn-btn ,
    ul.tab-style--1 li {
        color: #ffffff;
    }
    .portfolio-sacousel-inner .slick-dots li button::after,
    .rn-slick-dot .slick-dots li button::after {
        background: #ffffff;
        box-shadow: inset 0 0 0 5px #ffffff;
    }
    .creative-service-wrapper .row.creative-service .service.service__style--2 {
        background: #2c0000;
    }
    .contact-form--1 input, .contact-form--1 textarea ,
    .header-area.color-black a.rn-btn {
        border: 2px solid rgba(255, 255, 255, 0.2);
    }
    .header-area.color-black a.rn-btn:hover{
        border-color: $theme-color;
    }
    .header-area.header--fixed.sticky {
        background: #2c0000;
    }
    .header-area.header--fixed.sticky .header-wrapper a.rn-btn {
        color: #ffffff;
        border-color: #ffffff;
    }
    .header-area.header--fixed.sticky .header-wrapper a.rn-btn:hover{
        color: #ffffff;
        border-color: $theme-color;
    }
    .bg_image--25 {
        background-color: #101010;
        background-image: url(/assets/images/bg/bg-image-28.jpg) !important;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .backto-top>div {
        background-color: #000;
    }
    .backto-top svg {
        color: #fff;
    }
    .contact-form--1 input,
    .contact-form--1 textarea {
        color: rgba(198, 201, 216, 0.75)
    }

    .personal-portfolio-slider::before {
        display: none;
    }

    .copyright-text p {
        font-size: 16px !important;
        margin-left: 70px;
    }

    .single-tab-content .address {
        font-family: 'Roboto Mono', monospace;
        font-size: 16px;
        color: #d1b390;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .bg_image--1 {
            background-size: 140%, 160%;
            background-position: 50% 54%, 50% 50%, 50% 32%;
        }
        .copyright-text p {
            margin-left: 0;
        }
        .service-area {
            .art-bottom {
                top: 835px;
            }
        }
        .about-area {
            height: auto;
            .art-bottom {
                top: 784px;
            }
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {

        .service-area {
            .art-bottom {
                top: 727px;
            }
        }
        .about-area {
            height: auto;
            .art-bottom {
                top: 678px;
            }
        }
    }

    @media #{$md-layout} {
        .service-area {
            .art-bottom {
                top: 468px;
            }
        }
        .about-area {
            height: auto;
            img {
                display: none;
            }
            .art-bottom {
                top: 703px;
            }
        }
        .bg_image--1 {
            height: 300px;
            background-size: 150%,180%;
            background-position: 50% 88px,50% 50%,50% 32%;
        }
        .copyright-text p {
            margin-left: 0;
        }
        .header-style-two .humberger-menu span.text-white {
            color: #ebe6e0 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu {
            background: #101010;
        }
        .header-area .header-wrapper.menu-open .close-menu span {
            color: #ebe6e0 !important;
        }
        .header-area.header--fixed.sticky .humberger-menu span.text-white {
            color: #ebe6e0 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li a  {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a::after {
            border-color: #ffffff transparent transparent transparent;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a.open::after {
            border-color: transparent transparent $theme-color transparent
        }
    }
    @media #{$sm-layout} {
        background-size: 50%;

        .service-area {
            .art-bottom {
                display: none;
            }
        }
        .fix {
            height: auto !important;
        }
        .about-area {
            min-height: calc(100vh - 78px);
            height: auto;
            img {
                display: none;
            }
            .art-bottom {
                display: none;
            }
        }
        .bg_image--1 {
            height: calc(100vh - 78px);
            background-size: 370%,520%;
            background-position: 50% -130px,50% -20%,50% 30%;
        }
        .footer-right {
            background-size: 360%,600%;
            background-position: 64% 50%,19% 40%,50% 32%;
        }
        .copyright-text p {
            font-size: 14px !important;
        }
        .header-style-two .humberger-menu span.text-white {
            color: #ebe6e0 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu {
            background: #101010;
        }
        .header-area .header-wrapper.menu-open .close-menu span {
            color: #ebe6e0 !important;
        }
        .header-area.header--fixed.sticky .humberger-menu span.text-white {
            color: #ebe6e0 !important;
        }
        .service.service__style--2 .content p {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li a  {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a::after {
            border-color: #ffffff transparent transparent transparent;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a.open::after {
            border-color: transparent transparent $theme-color transparent
        }
        .tab-style--1 {
            margin-bottom: 40px;
        }
        .tab-style--1 li {
            width: 100%;
            padding: 10px 0 10px 0;
        }
        .single-tab-content ul li {
            margin-bottom: 30px !important;
        }
        .single-tab-content ul li a p {
            font-size: 13px !important;
        }
        .single-tab-content 
        .menutrigger svg {
            color: #dba64c !important;
        }
    }
}
